<div class="content">

  <h1 class="text-xl font-bold mb-8">
    - Under construction! -
  </h1>

  <img src="/assets/img/logo.png" alt="AcaBjj Logo" width="86" height="96">

  <h4 class="text-xl font-bold underline mt-8 mb-4">Herzlich Willkommen auf unserer Homepage!</h4>

  <p>
    Die Brazilian Jiu-Jitsu Schule wird von Ando Cardoso geleitet und du bist herzlichst eingeladen ab dem 3. Januar 2023 bei uns vorbei zu schauen, allenfalls
    schon mit Sportbekleidung oder dann mit dem GI.
  </p>

  <p class="mt-4">
    <span>
        Unser Trainingslokal befindet sich nur 5 Haltestelle vom Bahnhof Oerlikon entfernt und für die Autofahrer hat es auch genügend öffentliche Parkplätze vorhanden.
    </span>
  </p>

  <p class="mt-4">
    Bei Fragen kontaktiert mich ungeniert - stehe gerne zur Verfügung.
  </p>

  <div class="mt-4">Zürich, 17.11.2022</div>
  <div>Oss, Ando</div>
  <div>brownbelt</div>

  <div class="mt-4">ACA Brazilian Jiu-Jitsu Team</div>
  <div>Wallisellenstrasse 301a</div>
  <div>8050 Zürich-Oerlikon</div>

  <div class="mt-4">079 331 80 01</div>
  <div>&#64;aca.brazilianjiujitsu (Instagram)</div>

</div>
