<div class="custom-shape-divider-top">
  <svg class="h-4 md:h-6" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
    <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
  </svg>
</div>


<div class="min-h-screen flex flex-col justify-between">

  <div>
    <app-header></app-header>
  </div>

  <div>
    <router-outlet></router-outlet>
  </div>

  <div>
    <app-footer></app-footer>
  </div>

</div>


<!--<app-under-construction></app-under-construction>-->

